const fonts = [
    {value:'Albert Sans'},
    {value:'Alegreya Sans'},
    {value:'Archivo Narrow'},
    {value:'Assistant'},
    {value:'Baskervville'},
    {value:'Bebas'},
    {value:'Catamaran'},
    {value:'DM Serif Text'},
    {value:'Lato'},
    {value:'Manrope'},
    {value:'Maven Pro'},
    {value:'Matter'},
    {value:'Montserrat'},
    {value:'Nunito'},
    {value:'Nunito Sans'},
    {value:'Open Sans'},
    {value:'Pangolin'},
    {value:'Poppins'},
    {value:'Playfair Display'},
    {value:'RAG-Sans'},
    {value:'Raleway'},
    {value:'Roboto'},
    {value:'Shantell Sans'},
    {value:'Waterfall'}

]

export default fonts